import React from 'react';
import './PageImage.css';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container } from '@mui/system';
import Grid from '@mui/system/Unstable_Grid';
import Typography from '@mui/material/Typography';
import Button from '../Button/Button';
import Navebar from '../Navebar/Navebar';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const PageImage = () => {
    const data = useSelector((state) => state.mainImageWithData);
    const isSmallScreen = window.innerWidth < 500;
    let { imageUrl, isVisble } = data
    imageUrl = imageUrl ? imageUrl : 'https://cloud.appwrite.io/v1/storage/buckets/6547c3a995a531053481/files/extra/view?project=6547c05975e8877b1293&mode=admin';
    const backgroundStyle = {
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '1000px',
        position: 'relative', // Add relative positioning for child absolute positioning
        /* Add more styles as needed */
        animation: 'exampleAnimation 2s 1',
        transition: 'opacity .5s ease',
    };
    const smallScreenBackgroundStyle = {
        ...backgroundStyle,
        height: '700px',
    };
    const marginTopValue = isSmallScreen ? '200px' : '500px';
    const appliedBackgroundStyle = isSmallScreen ? smallScreenBackgroundStyle : backgroundStyle;

    return (
        <>

            <CssBaseline />
            <Box style={appliedBackgroundStyle} >
                <Navebar />
                {isVisble && (
                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: marginTopValue }}>
                        <Grid className='shed' sx={{ textAlign: 'center', marginTop: '40px' }}>
                            <Typography variant="h2" gutterBottom>Your vision, our expertise. Unforgettable events, made just for you.</Typography>
                            <Typography variant="span" gutterBottom >Customizing events just for you. Reach out and let's make your occasion extraordinary.</Typography>
                        </Grid>
                        <Container maxWidth="xl" sx={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Link to={'/contact-us'}> <Button className="shape2" value="Schedule a Consultation" /></Link>
                        </Container>
                    </Grid>
                )}
            </Box >
        </>
    )
}

export default PageImage