import { createSlice } from "@reduxjs/toolkit"

const gallerySlice = createSlice({
    name: "galleryImageData",
    initialState: [],
    reducers: {
        setgalleryImageData(state, action) {
            return action.payload
        }
    }
})
export const { setgalleryImageData, setgalleryvedioData } = gallerySlice.actions
export default gallerySlice.reducer;