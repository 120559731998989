import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useSelector } from 'react-redux'

export default function VedioBlock() {
  const galleryVedioData = useSelector((state) => state.galleryVedioData);
  return (
    <>
      {galleryVedioData.length > 0 ?
        <>
          <h2>Vedioes</h2>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {galleryVedioData.map((e, index) => (
                <Grid xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ maxWidth: 420, my: 5 }}>
                    <CardActionArea>
                      <CardMedia>
                        <iframe
                          width="420"
                          height="340"
                          src={e.link}
                          title={e.title}>
                        </iframe>
                      </CardMedia>

                    </CardActionArea>
                  </Card>
                </Grid>

              ))}
            </Grid>
          </Box>
        </> : ""}

    </>
  );
}