import React, { useEffect } from 'react'
import Base from '../Base'
import Slider from '../Sliders/Slider'
import { useSelector } from 'react-redux';

const Venue = () => {
    const venueData = useSelector((state) => state.homePageDataSlice);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Base>
            {venueData.map((data, index) => (
                <Slider data={data} key={index} />
            ))}
        </Base >
    )
}

export default Venue
