import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Container } from '@mui/material';
import Button from '../Button/Button';
import '../Sliders/Slider.css';
import configData from '../../appWrite/config';
import ThankyouModal from '../Model/ThankyouModal';
import { useSearchParams } from 'react-router-dom';

export default function Form() {
    const [queryParameters] = useSearchParams()
    const service_offer_code = queryParameters.get('packagename') || null;
    const [userData, setUserData] = useState({
        name: '',
        phoneno: '',
        email: '',
        address: '',
        city: '',
        state: 'Delhi',
        service_offer_code: service_offer_code,
    })
    const [showModal, setShowModal] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        const containsNumberInName = /\d/.test(userData.name);
        const containsNumberInCity = /\d/.test(userData.city);
        const containsOnlyNumbers = /^\d+$/.test(userData.phoneno);
        if (containsNumberInName || containsNumberInCity || !containsOnlyNumbers) {
            alert("Please Enter Correct Details !!!")
            return;
        }
        if (userData) {
            const response = await configData.sendUserData(userData)
            if (response) {
                setShowModal(true)
            }
        }
    }
    return (
        <>
            <Container
                maxWidth="sm"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'auto',

                }}>
                <Typography variant="h6" mb="8" gutterBottom className='childheading'>
                    Enter Your Details To Book Our Service
                </Typography>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container spacing={3} my={10}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                id="Name"
                                name="name"
                                label="Enter Your Full Name"
                                onChange={e => setUserData({ ...userData, name: e.target.value })}
                                value={userData.name}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="mobile no"
                                name="phoneno"
                                label="Enter Your Mobile Number"
                                inputProps={{ maxLength: 10 }}
                                type="tel"
                                onChange={e => setUserData({ ...userData, phoneno: e.target.value })}
                                value={userData.phoneno}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="Email"
                                name="Email"
                                label="Enter Your Email"
                                type="email"
                                fullWidth
                                onChange={e => setUserData({ ...userData, email: e.target.value })}
                                value={userData.email}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="Address"
                                name="Address"
                                label="Enter Your Address"
                                onChange={e => setUserData({ ...userData, address: e.target.value })}
                                value={userData.address}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="city"
                                name="city"
                                label="Enter Your City"
                                onChange={e => setUserData({ ...userData, city: e.target.value })}
                                value={userData.city}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="State"
                                name="State"
                                label="Delhi"
                                fullWidth
                                disabled
                                variant="outlined"
                            />
                        </Grid>
                        {service_offer_code &&
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="service_offer_code"
                                    name="service_offer_code"
                                    value={service_offer_code}
                                    fullWidth
                                    disabled
                                    variant="outlined"
                                />
                            </Grid>}
                        <Container maxWidth="xl" sx={{ marginTop: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <Link to={`/`} >  */}
                            <Button className='shape' value='Submit' />
                            {/* </Link> */}
                            {showModal && <ThankyouModal />}
                        </Container>
                    </Grid>
                </form>
            </Container>
        </>

    );
}
