import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route

} from "react-router-dom";
import { Provider } from 'react-redux'
import store from './store/store'
import Homepage from './Pages/Homepage';
import ServicesPage from './Pages/ServicesPage';
import OurVenuePage from './Pages/OurVenuePage';
import Aboutus from './Pages/Aboutus';
import Contactus from './Pages/Contactus';
import MoreAboutVenue from './component/Venue/MoreAboutVenue';
import PageNotFound from './Pages/PageNotFound';


const Routers = () => {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route>
                        <Route path="/" element={<Homepage />} />
                        <Route path="/services" element={<ServicesPage />} >
                            <Route path="more-details?:param1/:param2" element={<MoreAboutVenue />} />
                        </Route>
                        <Route path="/our-venue" element={<OurVenuePage />} >
                            <Route path="more-details" element={<MoreAboutVenue />} />
                        </Route>
                        <Route path="/about-us" element={<Aboutus />} />
                        <Route path="/contact-us" element={<Contactus />} />
                        <Route path="/more-details" element={<MoreAboutVenue />} />
                        <Route path="*" element={<PageNotFound />}
                        />

                    </Route>
                </Routes>
            </Router>
        </Provider>
    )
}

export default Routers
