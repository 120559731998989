import { createSlice } from "@reduxjs/toolkit"

const galleryVedioSlice = createSlice({
    name: "galleryVedioData",
    initialState: [],
    reducers: {
        setgalleryvedioData(state, action) {
            return action.payload
        }
    }
})
export const { setgalleryvedioData } = galleryVedioSlice.actions
export default galleryVedioSlice.reducer;