import React from 'react'
import ContactUs from '../component/ContactUs/ContactUs'
import { useDispatch } from 'react-redux'
import { setmainImageData } from '../store/mainImageSlice'

const Contactus = () => {
    const dispatch = useDispatch()
    const data = {
        imageUrl: 'https://images.pexels.com/photos/821754/pexels-photo-821754.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        isVisble: false
    }
    dispatch(setmainImageData(data))
    return (
        <ContactUs />
    )
}

export default Contactus
