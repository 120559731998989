import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import Button from '../Button/Button';
import { useNavigate } from 'react-router';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function ThankyouModal() {
    const navigation = useNavigate()
    const handleNavigate = () => {
        navigation('/')
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open='true'
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in='true'>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            Thank you
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                            for contacting us, we will get in touch with you soon...
                        </Typography>
                        <Container maxWidth="xl" sx={{ marginTop: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box onClick={handleNavigate}><Button value='Back to home' /></Box>
                        </Container>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}