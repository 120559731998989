import React from 'react'
import Services from '../component/Services/Services'
import { useDispatch } from 'react-redux'
import { setmainImageData } from '../store/mainImageSlice'
import { setHomePageData, removeAllData } from '../store/homePageDataSlice'

const ServicesPage = () => {
    const jsonData = [{
        title: 'Services',
        triggerPosition: '600',
        flexDirection: 'row-reverse',
        imageurl: 'https://images.pexels.com/photos/6405771/pexels-photo-6405771.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        heading: 'CORPORATE EVENTS',
        body: "Create a standout corporate event that captivates. Our designs ensure your gathering stands out, leaving a lasting impression on everyone who attends.",
        buttonName: 'Learn More',
        buttonLink: '/more-details',
        pageName: 'services',
        serviceName: 'one_gallery'
    }, {
        title: null,
        triggerPosition: '1300',
        flexDirection: 'row',
        imageurl: 'https://images.pexels.com/photos/169192/pexels-photo-169192.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        heading: 'WEDDING EVENTS',
        body: "At Naveen Events, our commitment is crafting exquisite and personalized wedding experiences. With meticulous attention to detail, we transform your vision into a breathtaking reality. From elegant venues to seamless coordination, trust us to make your special day unforgettable. Let's create timeless memories together at Naveen Events",
        buttonName: 'Learn More',
        buttonLink: '/more-details',
        pageName: 'services',
        serviceName: 'one_gallery'

    }, {
        title: null,
        triggerPosition: '2000',
        flexDirection: 'row-reverse',
        imageurl: 'https://images.pexels.com/photos/11310658/pexels-photo-11310658.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        heading: 'RING CEREMONY',
        body: "Designing a beautiful start to your forever, our ring ceremony services blend elegance with seamless planning. We craft moments that mirror your love story, ensuring a timeless celebration filled with joy and cherished memories.",
        buttonName: 'Learn More',
        buttonLink: '/more-details',
        pageName: 'services',
        serviceName: 'one_gallery'
    }, {
        title: null,
        triggerPosition: '2700',
        flexDirection: 'row',
        imageurl: 'https://images.pexels.com/photos/12153938/pexels-photo-12153938.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        heading: 'Haldi / Mendhi Ceremony',
        body: "Immerse in the joy of haldi and mehndi ceremonies with us. Our expertise weaves tradition and celebration into vibrant events, ensuring unforgettable moments filled with color and happiness.",
        buttonName: 'Learn More',
        buttonLink: '/more-details',
        pageName: 'services',
        serviceName: 'one_gallery'
    }, {
        title: null,
        triggerPosition: '4000',
        flexDirection: 'row-reverse',
        imageurl: 'https://images.pexels.com/photos/7859956/pexels-photo-7859956.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        heading: 'Birthday Celebration',
        body: "Let's craft the perfect birthday bash! Whether it's an intimate gathering or a grand celebration, we'll create memorable moments tailored just for you.",
        buttonName: 'Learn More',
        buttonLink: '/more-details',
        pageName: 'services',
        serviceName: 'one_gallery'
    }, {
        title: null,
        triggerPosition: '4800',
        flexDirection: 'row',
        imageurl: 'https://images.pexels.com/photos/13875401/pexels-photo-13875401.jpeg?auto=compress&cs=tinysrgb&w=800',
        heading: 'Devotional Event',
        body: "From divine rituals to spiritual gatherings, we curate heartfelt occasions that honor traditions and create lasting spiritual connections.",
        buttonName: 'Learn More',
        buttonLink: '/more-details',
        pageName: 'services',
        serviceName: 'one_gallery'
    },
    {
        title: null,
        triggerPosition: '5500',
        flexDirection: 'row-reverse',
        imageurl: 'https://images.pexels.com/photos/2291367/pexels-photo-2291367.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        heading: 'indoor / outdoor catering',
        body: "Crafting delicious menus for every occasion, indoors or outdoors, our catering service promises a delightful and easy culinary experience, ensuring top-notch quality for your event.",
        buttonName: 'Learn More',
        buttonLink: '/more-details',
        pageName: 'services',
        serviceName: 'one_gallery'
    }
    ]

    const dispatch = useDispatch()
    const data = {
        imageUrl: 'https://images.pexels.com/photos/1616113/pexels-photo-1616113.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        // imageUrl: '/static/media/serviceshome.ddbab1e4a6407ceb87c6.jpg',
        isVisble: false
    }
    dispatch(setmainImageData(data))
    dispatch(removeAllData())
    dispatch(setHomePageData((jsonData)))
    return (
        <Services />
    )
}

export default ServicesPage
