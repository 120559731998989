import React from 'react'
import Base from '../Base'
import Slider from '../Sliders/Slider';

const AboutUs = () => {

    return (
        <Base >

            <Slider data={{
                title: 'About Us', triggerPosition: '500', flexDirection: 'row',
                imageurl: 'https://images.pexels.com/photos/18897196/pexels-photo-18897196/free-photo-of-indian-wedding-ceremony-with-petals.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
                heading: null,
                body: `Since 1998, situated in the heart of South Delhi, our legacy spans over 25 years of expertise. With a diverse portfolio encompassing over 8 lakh successful events, we specialize in orchestrating various occasions, from the opulence of weddings to the finesse of ring ceremonies and vibrant haldi celebrations. Our proficiency extends to crafting unforgettable corporate events, seamless indoor/outdoor catering, and joyous birthday celebrations.

                As wedding venues near South Delhi, we understand the essence of your vision, and our meticulous planning transforms it into a masterpiece. Whether it's an intimate gathering or a grand affair, we personalize each event with passion and precision. Let's collaborate to create an extraordinary experience tailored just for you.`,
                buttonName: 'Schedule a Consultation',
                buttonLink: '/contact-us'
            }} />

        </Base>
    )
}

export default AboutUs
