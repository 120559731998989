import React from 'react'
import { Box, Container } from '@mui/system';
const DataArea = ({ component, title }) => {
    return (
        <Container maxWidth="xl" sx={{ marginTop: '80px' }}>
            <Box sx={{ height: 'auto' }}>
                {component}
            </Box>
        </Container>
    )
}

export default DataArea
