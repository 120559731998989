import React, { useEffect } from 'react'
import Base from '../Base'
import Grid from '@mui/material/Grid';
import './Contact.css';
import Form from './Form';
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import MarkunreadIcon from '@mui/icons-material/Markunread';

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Base>
            <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12} sm={8} md={8} sx={{ my: 5 }} order={{ xs: 2, md: 1 }}>
                    <Form />
                </Grid>
                <Grid item xs={12} sm={12} md={4} order={{ xs: 1, md: 2 }}>
                    <Box className="main">
                        <h2>START TO DESIGN YOUR EVENT</h2>
                        <p className="second-p">Looking for a team passionate about enhancing your event experience? We're dedicated to delivering exceptional quality and sustainability for your special occasion.
                            Let's create something extraordinary together for your event.</p>
                        <Box>
                            <List className='list'>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <PlaceIcon fontSize='large' sx={{ color: 'white', fontSize: '3.5rem' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="ADDRESS" secondary="Delhi, India" sx={{ marginLeft: 5 }} />
                                </ListItemButton>

                                <ListItemButton>
                                    <ListItemIcon>
                                        <WifiCalling3Icon fontSize='large' sx={{ color: 'white', fontSize: '3.5rem' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="PHONE" secondary=
                                        {<Typography
                                            className='linkText'
                                            component="a"
                                            href={`tel:${'+919818958555'}`}
                                            style={{ fontFamily: 'Verdana, sans-serif' }}
                                        >
                                            {'+91-9818958555'}
                                        </Typography>
                                        } sx={{ marginLeft: 5 }} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <MarkunreadIcon fontSize='large' sx={{ color: 'white', fontSize: '3.5rem' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="EMAIL" secondary=
                                        {<Typography
                                            className='linkText'
                                            component="a"
                                            href={`mailto:${"unnysoni98@gmail.com"}`}
                                            style={{ fontFamily: 'Verdana, sans-serif' }}
                                        >
                                            {"unnysoni98@gmail.com"}
                                        </Typography>
                                        }
                                        sx={{ marginLeft: 5 }} />
                                </ListItemButton>
                            </List>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Base >
    )
}

export default ContactUs
