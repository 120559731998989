import React from 'react'
import Slider from '../Sliders/Slider'
import { useSelector } from 'react-redux';

const HomePageSlider = () => {
    const serviceData = useSelector((state) => state.homePageDataSlice);
    return (
        <>
            {serviceData.map((data) => (
                <Slider data={data} />
            ))}
        </>
    )
}
export default HomePageSlider
