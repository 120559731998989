import { configureStore } from "@reduxjs/toolkit";
import mainImageWithData from './mainImageSlice'
import homePageDataSlice from './homePageDataSlice'
import galleryImageData from './gallerySlice'
import galleryVedioData from './galleryVedioSlice'


const store = configureStore({
    reducer: {
        mainImageWithData: mainImageWithData,
        homePageDataSlice: homePageDataSlice,
        galleryImageData: galleryImageData,
        galleryVedioData: galleryVedioData
    }
})
export default store;