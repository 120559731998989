import { createSlice } from "@reduxjs/toolkit"

const homePageDataSlice = createSlice({
    name: "homePageDataSlice",
    initialState: [],
    reducers: {
        setHomePageData(state, action) {
            return action.payload
        },
        removeAllData(state, action) {

            return
        }

    }
})
export const { setHomePageData, removeAllData } = homePageDataSlice.actions
export default homePageDataSlice.reducer;