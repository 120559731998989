import React from 'react'
import AboutUs from '../component/AboutUs/AboutUs'
import { useDispatch } from 'react-redux'
import { setmainImageData } from '../store/mainImageSlice'

const Aboutus = () => {
    const dispatch = useDispatch()
    const data = {
        imageUrl: 'https://images.pexels.com/photos/265791/pexels-photo-265791.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        isVisble: false
    }
    dispatch(setmainImageData(data))
    return (
        <AboutUs />
    )
}

export default Aboutus
