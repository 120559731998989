import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useSelector } from 'react-redux'
import { useMediaQuery, useTheme } from '@mui/material';

export default function Gallery() {

    const galleryData = useSelector((state) => state.galleryImageData);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const getCols = () => {
        if (isSmallScreen) {
            return 1; // For small screens, display one column
        }
        return 3; // For larger screens, display three columns
    };
    return (
        <>
            <h2>Gallery</h2>
            <ImageList sx={{ width: 'auto', height: 'auto' }} variant="woven" cols={getCols()} gap={8}>
                {galleryData.map((item) => (
                    <ImageListItem key={item.img}>
                        <img
                            srcSet={item.img}
                            src={item.img}
                            alt={item.title}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </>
    );
}
