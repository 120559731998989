import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '../Button/Button';
import { CssBaseline } from '@mui/material';
import { Container } from '@mui/system';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import image1 from '../../Images/ScreenDesign/offer1.jpg';
import image2 from '../../Images/ScreenDesign/Oco.jpg';
import { Link } from 'react-router-dom';

export default function Offer() {
    return (
        <>
            <CssBaseline />
            <Container maxWidth="xl" sx={{ marginTop: '80px' }}>
                <Typography variant="h1" className='heading' sx={{
                    display: {
                        xs: 'none', md: 'flex', fontWeight: 400,
                        fontSize: '3rem',
                        lineHeight: '1.167',
                        letterSpacing: '0em'
                    }
                }}>
                    {"Special Offer's"}
                </Typography>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} mt="40px">
                            <Card sx={{ maxWidth: 375, backgroundColor: 'rgba(255, 192, 203, 0.3)', p: 2 }}>
                                <CardMedia
                                    sx={{ height: 200, borderRadius: 10 }}
                                    image={image1}
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Dream Wedding Special
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        WELCOME DRINK (ANY 1) | VEG SNACKS (ANY 5) | RAITA / DAHI VADA |
                                        PANEER PREPARATION (ANY 1) | VEG PREPARATION (ANY 2) |
                                        DAL PREPARATION (ANY 1) | RICE (ANY 1) |
                                        INDIAN BREADS (ANY 4) | PICKLES, PAPAD, CHUTNEY, SALAD |
                                        DESSERT (ANY 1)
                                        <h2>Inclusions</h2>
                                        01 ROOM FOR THE BRIDE |
                                        STAGE WITH BACKDROP | COUCH FOR WEDDING COUPLE  | MANDAP |  DJ  | Tea/Coffee at Night |
                                        <br /> <br /><b>Cost: 99,999/- (Upto 100 Guest)</b>
                                        <br /> <br /><b>Special Offer: Only 89,999/- Upto 100 Guest!</b>
                                        <h3>Experience this incredible deal exclusively at Naveen Banquet.</h3>

                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Link to="/contact-us?packagename=DreamWeddingSpecial_5656"><Button value={"BOOK NOW"} /></Link>
                                    </Container>
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} mt="40px">
                            <Card sx={{ maxWidth: 375, backgroundColor: 'rgba(255, 192, 203, 0.3)', p: 2 }}>
                                <CardMedia
                                    sx={{ height: 325, borderRadius: 10 }}
                                    image={image2}
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Outdoor Catering Special
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        WELCOME DRINK (ANY 1) | VEG SNACKS (ANY 5) | RAITA / DAHI VADA |
                                        PANEER PREPARATION (ANY 1) | VEG PREPARATION (ANY 2) |
                                        DAL PREPARATION (ANY 1) | RICE (ANY 1) |
                                        INDIAN BREADS (ANY 4) | PICKLES, PAPAD, CHUTNEY, SALAD |
                                        DESSERT (ANY 2) | Ice Cream (Any 1)
                                        <br /> <br /><b>Cost: 799-/Per Plate</b>
                                        <br /> <br /><b>Special Offer: Only 699-/Per Plate</b>
                                        <h3>Experience this incredible deal exclusively with us.</h3>
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Link to="/contact-us?packagename=OutdoorCateringSpecial_5656"><Button value={"BOOK NOW"} /></Link>
                                    </Container>
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} mt="40px">
                            <Card sx={{ maxWidth: 375, backgroundColor: 'rgba(255, 192, 203, 0.3)', p: 2 }}>
                                <CardMedia
                                    sx={{ height: 140, borderRadius: 10 }}
                                    image="https://images.pexels.com/photos/12153938/pexels-photo-12153938.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Haldi Mehndi Special
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        WELCOME DRINK (ANY 1) | VEG SNACKS (ANY 5) | RAITA / DAHI VADA |
                                        PANEER PREPARATION (ANY 1) | VEG PREPARATION (ANY 2) |
                                        DAL PREPARATION (ANY 1) | RICE (ANY 1) |
                                        INDIAN BREADS (ANY 4) | PICKLES, PAPAD, CHUTNEY, SALAD |
                                        DESSERT (ANY 1)
                                        <h2>Inclusions</h2>
                                        Dazzling Setup for Haldi & Mendhi Ceremony | DJ | 2 Events<br />
                                        - Haldi Ceremony in the Morning | Mendhi Ceremony in the Evening<br />
                                        - Delectable 2-Time Buffet | Unique Theme for Each Function
                                        <br /><br />
                                        <b>Cost: ₹1,49,999/- (Upto 80 Guest)</b>
                                        <br /><br />
                                        <b>Special Offer: Only ₹1,38,999/- Upto 80 Guest!</b>
                                        <h3>Experience this incredible deal exclusively at Naveen Banquet.</h3>
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Link to="/contact-us?packagename=Haldi_Mehndi_Special_5656"><Button value={"BOOK NOW"} /></Link>
                                    </Container>
                                </CardActions>
                            </Card>
                        </Grid>

                    </Grid>
                </Box>
            </Container >
        </>
    );
}