import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import image from '../../Images/ScreenDesign/couple.png'

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: "#e5ce5"
};

const Loader = () => {
    useEffect(() => {
        document.title = 'Loading... ';
    })
    return (
        <div style={loaderContainerStyle}>
            <CircularProgress size={200} thickness={2} style={{ 'color': '#e5ce5e' }} />
            <img
                src={image}
                alt="LOADING"
                style={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '130px',

                }}
            />
        </div>
    );
};

export default Loader;