
import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container } from '@mui/system';
import Grid from '@mui/system/Unstable_Grid';
import CountUp from 'react-countup';
import '../Sliders/Slider.css';
import Typography from '@mui/material/Typography';

export default function Counter() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const elementPosition = document.getElementById('counter').offsetTop;
      const isVisible = scrollPosition > elementPosition - window.innerHeight * 0.75;

      setIsVisible(isVisible);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <CssBaseline />
      <Container maxWidth="xl">
        <Box>
          <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid md={4}
              sm={12}
              id="counter" sx={{ textAlign: 'center', marginTop: '40px' }}>
              {isVisible ? <Typography variant="h3" gutterBottom className='childheading'> {isVisible && <CountUp end={25} />}</Typography> : <Typography variant="h3" gutterBottom className='childheading'> XX</Typography>}
              <Typography variant="h5" gutterBottom className='childbody'>
                Professional Tenure
              </Typography>
            </Grid>
            <Grid md={4}
              sm={12}
              sx={{ textAlign: 'center', marginTop: '40px' }}>
              <Typography variant="span" gutterBottom className='childheading'>Naveen Spectra Events</Typography>
            </Grid>
            <Grid md={4}
              sm={12}
              id="counter" sx={{ textAlign: 'center', marginTop: '40px' }}>
              {isVisible ? <Typography variant="h3" gutterBottom className='childheading'> {isVisible && <CountUp end={789768} />}</Typography> : <Typography variant="h3" gutterBottom className='childheading'> XXXX</Typography>}
              <Typography variant="h5" gutterBottom className='childbody'>
                Total Event Instances
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
