import { createSlice } from "@reduxjs/toolkit"

const mainImageSlice = createSlice({
    name: "mainImageWithData",
    initialState: { imageUrl: '', isVisble: false },
    reducers: {
        setmainImageData(state, action) {
            return action.payload
        },
        removeAllData(state, action) {

            return { imageUrl: '', isVisble: false };
        }

    }
})
export const { setmainImageData, removeAllData } = mainImageSlice.actions
export default mainImageSlice.reducer;