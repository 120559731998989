import React, { useState, useEffect } from 'react'
import PageImage from './PageImage/PageImage'
import Footer from './Footer/Footer'
import Loader from './Loader/Loader'

const Base = ({ children }) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        document.title = 'Naveen Spectra Events';
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <PageImage />
                    {children}
                    <Footer />
                </>
            )}
        </>
    )
}

export default Base