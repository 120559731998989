import { Client, Databases, ID } from "appwrite";
import conf from "../conf/conf";
class Config {
    client = new Client();
    database;
    constructor() {
        this.client
            .setEndpoint(conf.appWriteUrl) // Your API Endpoint
            .setProject(conf.appWriteProjectId);

        this.database = new Databases(this.client);
    }
    async getlinks() {
        try {
            return await this.database.listDocuments(
                '6547c155b38dee93b6c7',
                '658f060ae562eb352b52'

            )
        } catch (error) {
            console.log("Appwrite serive :: getlinks :: error", error);
            return false
        }
    }
    async sendUserData(userData) {
        try {
            return await this.database.createDocument(
                conf.appWriteDatabaseId,
                conf.appWriteCustomerDataId,
                ID.unique(),
                userData
            )

        } catch (error) {
            console.log("Appwrite serive :: getlinks :: error", error);
            return false
        }
    }
}
const configData = new Config();
export default configData
