import { Client, Storage } from "appwrite";
import conf from "../conf/conf";

class ImageGet {
    client = new Client();
    storage;
    constructor() {
        this.client = new Client()
            .setEndpoint(conf.appWriteUrl) // Your API Endpoint
            .setProject(conf.appWriteProjectId);

        this.storage = new Storage(this.client);
    }

    getFilePreview(fileId) {
        return this.storage.getFilePreview(
            conf.appWriteBucketId,
            fileId
        )
    }
    getFilelist() {
        return this.storage.listFiles(
            conf.appWriteBucketId
        )
    }
    getServiceFilelist() {
        return this.storage.listFiles(
            '6591d67e0f23dd80fcb3'
        )
    }
    getServiceFilePreview(fileId) {
        return this.storage.getFilePreview(
            '6591d67e0f23dd80fcb3',
            fileId
        )
    }
}

const imageget = new ImageGet();
export default imageget;
