import React from 'react'
import Venue from '../component/Venue/Venue'
import { useDispatch } from 'react-redux'
import { setmainImageData } from '../store/mainImageSlice'
import { setHomePageData, removeAllData } from '../store/homePageDataSlice'

const OurVenuePage = () => {
    const jsonData = [{
        title: 'Venues',
        triggerPosition: '600',
        flexDirection: 'row-reverse',
        imageurl: 'https://lh3.googleusercontent.com/p/AF1QipOsMW2q46h6u5eUGD7yIDqSNglcbqZvjjOpgpUt=w960-h700-n-o-v1',
        heading: 'Naveen Banquet',
        body: `Looking for the ultimate banquet hall for a fantastic birthday party near you? Your search ends at 
        Naveen Restaurant, Sweet's & Banquet. Situated in the heart of Delhi at Village, Block D, Molar Band 
        Extension, Badarpur, Faridabad, our banquet hall stands out as the ideal spot for your celebrations. 
        Our dedicated banquet hall is meticulously designed to host 1st birthday parties or an intimate gathering . Offering an 
        unmatched ambiance and top-notch amenities, our space ensures an unforgettable experience.
        Explore our versatile banquet options and experience the epitome of elegance and functionality. 
        At Naveen Restaurant, Sweet's & Banquet, we provide the perfect backdrop for your special moments.
        Book your celebration today and indulge in the best banquet hall experience for your birthday party 
        in Delhi. Elevate your celebrations with us at Naveen Restaurant, Sweet's & Banquet!`,
        buttonName: 'Learn More',
        buttonLink: '/more-details',
        pageName: 'venue',
        serviceName: 'naveen_banquet'
    }, {
        title: null,
        triggerPosition: '1300',
        flexDirection: 'row',
        imageurl: 'https://lh3.googleusercontent.com/p/AF1QipOZnPKV-seN3Q41RM5iJL2P-t1zxOR24K-wXkYv=w960-h700-n-o-v1',
        heading: 'Yadi Garden',
        body: `Experience the Charm of Yadi Garden, Badarpur's Premier Banquet Hall and Garden Venue"
        Yadi Garden, nestled in the vibrant Mohan Baba Nagar, Molarband, Badarpur, is the quintessence of elegance.
         Our meticulously crafted garden venue offers an enchanting setting for your most cherished celebrations, 
         be it weddings or birthdays. Conveniently located along the 1st 60 Feet Road in Tajpur Pahari, 
         Yadi Garden promises an idyllic ambiance for your special moments in Delhi.
        Conveniently situated near the Badarpur border in Delhi, Yadi Garden's banquet hall and sprawling 
        garden spaces epitomize sophistication and versatility. Our venue at 37 Sector near the Badarpur 
        border stands as a beacon for those seeking a wedding hall or garden venue on the Delhi side of 
        Badarpur border. Yadi Garden's offerings cater to every event need, ensuring an unforgettable experience 
        in the heart of Delhi.`,
        buttonName: 'Learn More',
        buttonLink: '/more-details',
        pageName: 'venue',
        serviceName: 'yadi_garden'

    }, {
        title: null,
        triggerPosition: '2000',
        flexDirection: 'row-reverse',
        imageurl: 'https://lh3.googleusercontent.com/p/AF1QipOGMsnBC8ApICurbfwtqK6H-yw8HFXfRddsE7OQ=w960-h700-n-o-v1',
        heading: 'Hari Kirshna Vatika',
        body: `Celebrate Your Moments at Hari Krishna Vatika - Your Ultimate Venue Choice in Pehlad Pur, Delhi!
        In search of the perfect garden or banquet hall in Pehlad Pur, New Delhi? Look no further than Hari Krishna Vatika. Situated at Sharma Market, Block A, Pul Pehlad Pur, New Delhi, our venue offers enchanting gardens and sophisticated banquet halls for your celebrations. Create unforgettable memories amidst serene greenery or within elegant interiors, tailored to meet your event needs. Experience the magic of Hari Krishna Vatika and make every occasion a cherished one!`,
        buttonName: 'Learn More',
        buttonLink: '/more-details',
        pageName: 'venue',
        serviceName: 'yadi_garden'
    }]
    const dispatch = useDispatch()
    const data = {
        imageUrl: 'https://images.pexels.com/photos/169198/pexels-photo-169198.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        // imageUrl: '/static/media/mainvenu.f5aefe2dbfbc9b18cffd.png',
        isVisble: false
    }
    dispatch(setmainImageData(data))
    dispatch(removeAllData())
    dispatch(setHomePageData((jsonData)))
    return (
        <>
            <Venue />

        </>
    )
}

export default OurVenuePage
