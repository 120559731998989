import React from 'react'
import Base from '../Base'
import Counter from '../Counter/Counter'
import HomePageSlider from '../HomePageSlider/HomePageSlider'
import Offer from '../Offer/Offer'

const MainHome = () => {
    return (
        <Base >
            <Counter />
            <Offer />
            <HomePageSlider />
        </Base >
    )
}

export default MainHome
