import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '../component/Button/Button';
import { Link } from 'react-router-dom';

export default function PageNotFound() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh'
            }}
        >
            <Container maxWidth="md">
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        <Typography variant="h1">
                            404
                        </Typography>
                        <Typography variant="h6">
                            The page you’re looking for doesn’t exist.
                        </Typography>
                        <Container maxWidth="xl" sx={{ marginTop: '40px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <Link to={'/'} > <Button value={'Back Home'} /></Link>
                        </Container>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <img
                            src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
                            alt=""
                            width={400} height={200}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}