import React from 'react';
import { Box, Container } from '@mui/system';
import Grid from '@mui/system/Unstable_Grid';
import Button from '../Button/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import './Slider.css';
import { Link } from 'react-router-dom';

export default function Slider({ data }) {
    const { title, triggerPosition, flexDirection, imageurl, heading, body, buttonLink, buttonName, pageName, serviceName } = data;
    const [animate, setAnimate] = React.useState(false);

    const gridStyles = {
        opacity: animate ? 1 : 0,
        textAlign: 'left',
        marginTop: '20px',
        transition: 'opacity 0.8s ease-in-out, transform 0.5s ease-in-out',
        overflowX: 'hidden'
    };

    React.useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const triggerPositions = parseInt(triggerPosition, 10);

            if (scrollPosition > triggerPositions) {
                setAnimate(true);
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [data, triggerPosition]);

    return (
        <>
            <CssBaseline />
            <Container maxWidth="xl" sx={{ marginTop: '80px' }}>
                <Typography variant="h1" className='heading' sx={{
                    display: {
                        xs: 'none', md: 'flex', fontWeight: 400,
                        fontSize: '3rem',
                        lineHeight: '1.167',
                        letterSpacing: '0em'
                    }
                }}>
                    {title}
                </Typography>
                <Typography variant="h3" className='heading' sx={{ display: { xs: 'flex', md: 'none', justifyContent: 'center' } }}>
                    {title}
                </Typography>
                <Box sx={{ height: 'auto' }}>
                    <Grid container spacing={2} columnSpacing={{ xs: 0, sm: 5, md: 11 }} sx={{ display: 'flex', flexDirection: { flexDirection }, justifyContent: 'center', alignItems: 'center' }}>
                        <Grid
                            md={8}
                            xs={12}
                            sx={{
                                ...gridStyles,
                                transform: animate ? 'translateX(0)' : 'translateX(50%)',

                            }}
                        >
                            <img
                                src={imageurl}
                                style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto', width: 'auto' }}
                                alt=""
                                title="Corporate-Events"
                            />

                        </Grid>
                        <Grid
                            md={4}
                            xs={12}
                            sx={{
                                ...gridStyles,
                                transform: animate ? 'translateX(0)' : 'translateX(-50%)'
                            }}
                        >
                            <Typography variant="h3" gutterBottom className='childheading'>
                                {heading}
                            </Typography>
                            <Typography variant="span" gutterBottom className='childbody'>{body} </Typography>
                            <Container maxWidth="xl" sx={{ marginTop: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {pageName ? <Link to={`${buttonLink}?p1=${pageName}&p2=${serviceName}`} > <Button value={buttonName} /></Link> : <Link to={`${buttonLink}`} > <Button value={buttonName} /></Link>}
                            </Container>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
}
