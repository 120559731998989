import React, { useEffect } from 'react'
import Container from '../Container/DataArea'
import Gallery from '../Gallery/Gallery'
import VedioBlock from '../Vedio/VedioBlock'
import Base from '../Base'
import { useLocation } from 'react-router-dom';
import imageget from '../../appWrite/imageGet';
import { useDispatch } from 'react-redux'
import { setgalleryImageData } from '../../store/gallerySlice'
import configData from '../../appWrite/config';
import { setgalleryvedioData } from '../../store/galleryVedioSlice'


const MoreAboutVenue = () => {

    const dispatch = useDispatch()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const param1 = searchParams.get('p1');
    const param2 = searchParams.get('p2');
    useEffect(() => {
        const fetchImage = async () => {
            try {
                window.scrollTo(0, 0);
                if (param1 === 'venue') {
                    const data = await imageget.getFilelist()
                    const links = await configData.getlinks()
                    const vedioLink = links.documents
                    const files = data.files
                    const newImageData = []
                    const newVedioLinkData = []
                    files.forEach((e, index) => {
                        if (e.$id.includes(`${param1}_${param2}`)) {
                            const image = imageget.getFilePreview(e.$id)
                            let url = image.href;
                            const itemData = {
                                img: url,
                                title: index
                            }
                            newImageData.push(itemData);
                        }
                    });
                    vedioLink.forEach((e) => {
                        if (e.service.includes(`${param1}_${param2}`)) {
                            let url = e.link;
                            const vedioLinkData = {
                                link: url,
                                title: e.title
                            }
                            newVedioLinkData.push(vedioLinkData);
                        }
                    });

                    dispatch(setgalleryImageData((newImageData)))
                    dispatch(setgalleryvedioData(newVedioLinkData))
                }
                else if (param1 === 'services') {
                    const data = await imageget.getServiceFilelist()
                    const files = data.files
                    const newImageData = []
                    files.forEach((e, index) => {
                        if (e.$id.includes(`${param1}_${param2}`)) {
                            const image = imageget.getServiceFilePreview(e.$id)
                            let url = image.href;
                            const itemData = {
                                img: url,
                                title: index
                            }
                            newImageData.push(itemData);
                        }
                    });
                    dispatch(setgalleryImageData((newImageData)))
                }
            } catch (error) {
                console.log(error)
            }
        };

        fetchImage();
    }, [param1, param2, dispatch]);
    return (

        <Base>
            <Container component={< Gallery />} />
            <Container component={<VedioBlock />} />
        </Base >
    )
}

export default MoreAboutVenue
