import React from 'react'
import MainHome from '../component/Home/MainHome'
import { useDispatch } from 'react-redux'
import { setmainImageData } from '../store/mainImageSlice'
import { setHomePageData, removeAllData } from '../store/homePageDataSlice'
import homeVenue from '../Images/ScreenDesign/homeVenue.png'
import servicehome from '../Images/ScreenDesign/serviceshome.jpg'

const Homepage = () => {
    const jsonData = [{
        title: 'About Us',
        triggerPosition: '1600',
        flexDirection: 'row',
        imageurl: 'https://images.pexels.com/photos/18897196/pexels-photo-18897196/free-photo-of-indian-wedding-ceremony-with-petals.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        heading: null,
        body: `Find the ideal wedding venues near South Delhi that transcend imagination. With over 8 lakh successful events, we've perfected the art of creating extraordinary experiences. Whether it's the grandeur of a wedding, the vibrancy of ceremonies like haldi, mendhi, or the professionalism of corporate events, our spaces are tailored to your vision.

        Choose from our versatile indoor and outdoor catering services, ideal for birthday celebrations or any special occasion`,
        buttonName: 'Schedule a Consultation',
        buttonLink: '/contact-us'
    }, {
        title: 'Services',
        triggerPosition: '2300',
        flexDirection: 'row-reverse',
        imageurl: servicehome,
        body: `Discover unforgettable wedding venues and exceptional spaces for corporate events near South Delhi. We specialize in curating tailor-made celebrations, from splendid weddings and vibrant ring ceremonies to lively haldi and mehndi ceremonies. Our expertise lies in offering top-notch indoor and outdoor catering, ensuring every moment of your event is infused with unparalleled quality and attention to detail. Explore the perfect setting for your birthday celebration and other special occasions. Find your dream venue right here, just a stone's throw away!`,
        buttonName: 'Learn More',
        buttonLink: '/services'
    }, {
        title: "Our Venues",
        triggerPosition: '3300',
        flexDirection: 'row',
        imageurl: homeVenue,
        heading: 'Venues',
        body: "Discover the Perfect Venue: From Banquet Halls to Scenic Gardens! Looking for a banquet hall or a picturesque garden for a birthday party near Badarpur, Delhi? At Naveen Restaurant, Sweets & Banquet, and Yadi Garden, we offer a range of exquisite spaces ideal for every celebration.Our venues, nestled in the heart of Badarpur, Faridabad, present diverse options tailored to your event needs.Whether it's an intimate gathering or a grand celebration, explore our banquet halls and lush gardens, ensuring a delightful experience. Find your ideal banquet hall or garden setting at our venues near Badarpur Border, Delhi!",
        buttonName: 'Learn More',
        buttonLink: '/our-venue'

    }]
    const data = {
        imageUrl: 'https://images.pexels.com/photos/1444442/pexels-photo-1444442.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',

        isVisble: true
    }
    const dispatch = useDispatch()

    dispatch(setmainImageData(data))
    dispatch(removeAllData())
    dispatch(setHomePageData((jsonData)))

    return (
        <MainHome />
    )
}

export default Homepage
