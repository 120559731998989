const conf = {
    appWriteUrl: String(process.env.REACT_APP_APPWRITE_URL),
    appWriteProjectId: String(process.env.REACT_APP_APPWRITE_PROJECT_ID),
    appWriteDatabaseId: String(process.env.REACT_APP_APPWRITE_DATABASE_ID),
    appWriteCustomerDataId: String(process.env.REACT_APP_APPWRITE_COLLECTION_CUSTOMER_DATA_ID),
    appWriteVedioDataId: String(process.env.REACT_APP_APPWRITE_COLLECTION_VEDIO_DATA_ID),
    appWriteBucketId: String(process.env.REACT_APP_APPWRITE_BUCKET_ID)

}

export default conf