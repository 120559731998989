import React, { useEffect } from 'react'
import Base from '../Base'
import Slider from '../Sliders/Slider'
import { useSelector } from 'react-redux';

function Services() {
    const serviceData = useSelector((state) => state.homePageDataSlice);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Base>
            {serviceData.map((data) => (
                <Slider data={data} />
            ))}
        </Base >
    )
}

export default Services
